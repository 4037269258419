<template>
    <div>
        <b-card-code >
            <b-tabs>
                <b-tab title="Informacion del Proyecto">
                    <b-row v-if="!project.ProjectCode">
                        <b-col md="3" xl="3" class="mb-1">
                            <b-badge variant="primary">
                                Código del Proyecto: {{project.projectCode}}
                            </b-badge>
                        </b-col>   
                    </b-row>

                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Código del Proyecto" label-for="basicInput">
                            <b-form-input v-model="project.projectCode" placeholder="Digite el Código"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Nombre del Proyecto" label-for="basicInput">
                            <b-form-input v-model="project.projectName" placeholder="Digite el nombre"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1" v-if="userIsAdmin">
                            <b-form-group label="Link Grupo Whatsapp" label-for="basicInput">
                            <b-form-input v-model="project.whatsappGroupLink" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    

                    

                    <b-row>
                        <b-col md="4" xl="4" class="mb-1">
                        <label for="example-datepicker">Plazo para el Recaudo</label>
                            <b-form-datepicker v-model="project.collectDeadline" id="example-datepicker1" class="mb-1"/>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                        <label for="example-datepicker">Inicio del Proyecto</label>
                            <b-form-datepicker v-model="project.startDate" id="example-datepicker2" class="mb-1"/>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                        <label for="example-datepicker">Finalización del Proyecto</label>
                            <b-form-datepicker v-model="project.endDate" id="example-datepicker3" class="mb-1"/>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" xl="6" class="mb-1">
                            <label for="example-datepicker">Detalles y Objetivos del Proyecto</label>
                            <b-form-textarea v-model="project.details" id="textarea-default" placeholder="Digite detalles del proyecto y la ejecución" rows="5"/>
                        </b-col>  
                        <b-col md="6" xl="6" class="mb-1">
                            <label for="example-datepicker">Manejo de Riesgos</label>
                            <b-form-textarea v-model="project.risksManagement" id="textarea-default" placeholder="Digite detalles del manejo de riesgos" rows="5"/>
                        </b-col>   
                    </b-row>  
                    <b-row>
                        <b-col md="6" xl="6" class="mb-1">
                            <label for="example-datepicker">Plan de comunicaciones</label>
                            <b-form-textarea v-model="project.communicationPlan" id="textarea-default" placeholder="Digite detalles del plan de comunicaciones" rows="5"/>
                        </b-col>
                        <b-col v-if="userIsAdmin" md="6" xl="6" class="mb-1">
                            <label for="example-datepicker">Historia del Proyecto</label>
                            <b-form-textarea v-model="project.projectStory" id="textarea-default" placeholder="Digite la historia del proyecto" rows="5"/>
                        </b-col>
                    </b-row>
                </b-tab>

                
                <b-tab title="Informacion Técnica">


                    <b-row>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Tipo de Proyecto" label-for="basicInput">                        
                                <b-form-select @change="projectTypeSelected" v-model="project.projectType" :options="projectTypeOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Rentabilidad - E.A (%)" label-for="basicInput">
                                <b-form-input v-model.number="project.projectProfitability" type="number" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Comisión Productor" label-for="basicInput">
                                <b-form-input v-model.number="project.producerCommission" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Tipo de Ganado" label-for="basicInput">                        
                                <b-form-select v-model="project.projectCattleType" :options="projectCattleTypeOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Cantidad de Novillos" label-for="basicInput">
                                <b-form-input id="cattles" @change="amountOfCattlesTyped" v-model.number="project.amountOfCattles" type="number" placeholder="Digite" ref="myTextField"/>                                                                                                    
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Peso Inicial" label-for="basicInput">
                                <b-form-input id="number4" @change="initialWeightTyped" v-model.number="project.initialWeight" type="number" placeholder="Digite" ref="myTextField"/>                                                                                                    
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Peso Final" label-for="basicInput">
                                <b-form-input id="number5" @change="finalWeightTyped" v-model.number="project.finalWeight" type="number" placeholder="Digite" ref="myTextField"/>                                                                                                    
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Kilos en Pie Solicitados" label-for="basicInput">                                
                                <cleave id="number1" v-model.number="kilosEnPie"  class="form-control" :options="options.number" disabled></cleave>                                
                            </b-form-group>
                        </b-col>                    
                    </b-row>

                    <b-row v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">                    
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Kilos Mínimos Aceptados" label-for="basicInput">
                                <cleave id="number2" v-model.number="project.minimumInvestmentRequired" class="form-control" :options="options.number" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>  
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Ganancia Diaria Promedio (gr)" label-for="basicInput">
                                <b-form-input v-model.number="project.cattleWeightAverageGain" type="number" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Peso total del lote a Vender (kg)" label-for="basicInput">
                                <b-form-input v-model.number="project.totalBatchWeight" type="number" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Sexo" label-for="basicInput">                        
                                <b-form-select v-model="project.projectSex" :options="projectSexOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>                        
                    </b-row> 

                    <div v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'"> 
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1">
                                <h3>Precio Kilogramo</h3>
                            </b-col>                        
                        </b-row> 
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Precio Kg (Compra)" label-for="basicInput">
                                    <b-form-input v-model.number="project.initialKilogramPrice" type="number" placeholder="Digite"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Precio Kg (Venta)" label-for="basicInput">
                                    <b-form-input v-model.number="project.finalKilogramPrice" type="number" placeholder="Digite"/>
                                </b-form-group>
                            </b-col>      
                        </b-row> 
                        <b-row v-if="userIsAdmin">
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Flete por Kg" label-for="basicInput">                                
                                    <b-form-input v-model.number="project.estimatedFreightCost" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col>  
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Seguro Por Kg" label-for="basicInput">
                                    <b-form-input v-model.number="project.insurancePricePerKilogram" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Orejeras Por Kg" label-for="basicInput">
                                    <b-form-input v-model.number="project.orejerasPerKilogram" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="4x1000 Por Kg" label-for="basicInput">
                                    <b-form-input v-model.number="project.fourPerThousandPerKilogram" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Comercialización Sosty por Kg" label-for="basicInput">
                                    <b-form-input v-model.number="project.sostyComission" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Mandato Sosty por Kg" label-for="basicInput">
                                    <b-form-input v-model.number="project.mandatoPercentage" type="number" @change="kilogramSubValueChanged"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" xl="3" class="mb-1">
                                <b-form-group label="Valor Total Kg (Compra)" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalPricePerKilogram" type="number" disabled/>
                                </b-form-group>
                            </b-col>     
                        </b-row> 
                    </div>

                    <b-row v-if="project.projectType == 'Cria'">                        
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Cantidad de Terneros a Vender" label-for="basicInput">
                                <b-form-input v-model.number="project.amountOfCalvesToSell" placeholder="Digite"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Cantidad de Toros" label-for="basicInput">
                                <b-form-input v-model.number="project.amountOfBulls" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>   
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Cantidad de Vacas" label-for="basicInput">
                                <b-form-input v-model.number="project.amountOfCows" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>   
                    </b-row>

                    <b-row v-if="project.projectType == 'Cria'">
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="(%) Comision Sosty en la Venta de Terneros" label-for="basicInput">
                                <b-form-input v-model.number="project.sostyComissionOnSale" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>   
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="(%) Crías Año / Vaca" label-for="basicInput">
                                <b-form-input v-model.number="project.calvesPercentage" placeholder="Digite"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="2" xl="2" class="mb-1">
                            <b-form-group label="Peso del Ternero al Destete (Kg)" label-for="basicInput">
                                <b-form-input v-model.number="project.calveWeigthAtWeaning" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                    </b-row>                    

                    <div v-if="project.projectType == 'Cria'"> 
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1">
                                <h3>Costos/Inversión Requerida</h3>
                            </b-col>
                        </b-row> 
                        <b-row>
                            <b-col md="2" xl="2" class="mb-1">
                                <b-form-group label="Costo Total Vacas" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalCostCows" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Costo Total Toros" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalCostBulls" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col>    
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Costo Total Transporte" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalFreightCost" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Costo Total Seguro" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalInsurance" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col>  
                        </b-row> 
                        <b-row>
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Costo Total Orejeras" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalOrejeras" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col>  
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Total 4 x Mil" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalFourPerThousand" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Total Comercializacion Sosty" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalSostyComercialization" type="number" @change="calculateTotalCostBreedingProject"/>
                                </b-form-group>
                            </b-col> 
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Costo Total del Proyecto" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalCostBreedingProject" type="number" disabled/>
                                </b-form-group>
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1">
                                 <b-form-group label="Cantidad de sostycs del Proyecto" label-for="basicInput">
                                    <b-form-input v-model.number="project.totalUnits" type="number" @change="calculateUnitsPrice"/>
                                </b-form-group>
                            </b-col>  
                            <b-col md="3" xl="3" class="mb-1">
                                 <b-form-group label="Valor por Sostyc" label-for="basicInput">
                                    <b-form-input v-model.number="project.unitPrice" type="number" disabled/>
                                </b-form-group>
                            </b-col> 
                        </b-row>
                    </div>

                    
                    <div v-if="project.projectType == 'Cria'"> 
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1">
                                <h3>Proyección Financiera</h3>
                            </b-col>                        
                        </b-row> 
                        <b-row>
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Ingresos Venta de Terneros" label-for="basicInput">
                                    <b-form-input v-model.number="project.calvesRevenue" type="number" placeholder="Digite"/>
                                </b-form-group>
                            </b-col>    
                            <b-col md="2" xl="2" class="mb-1">
                                 <b-form-group label="Ingresos Liquidación" label-for="basicInput">
                                    <b-form-input v-model.number="project.liquidationRevenue" type="number" placeholder="Digite"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>

                </b-tab>
                
                <b-tab title="Video y Fotos">
                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Video 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione" v-model="videoFile1" />
                                <b-card-text @click="openVideo(project.projectVideoUrl)" v-if="project.projectVideoUrl" class="cursor-pointer sosty-font-smaller" style="color:#00BD56">Ver<feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Imagen 1" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una Imagen" v-model="projectImageFile1" />
                                <b-card-text @click="openDocument(project.projectImageUrl1)" accept="image/*" v-if="project.projectImageUrl1" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Imagen 2" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una Imagen" v-model="projectImageFile2" />
                                <b-card-text @click="openDocument(project.projectImageUrl2)" accept="image/*" v-if="project.projectImageUrl2" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Imagen 3" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una Imagen" v-model="projectImageFile3" />
                                <b-card-text @click="openDocument(project.projectImageUrl3)" accept="image/*" v-if="project.projectImageUrl3" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col> 
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Imagen 4" label-for="basicInput">
                                <b-form-file placeholder="Seleccione una Imagen" v-model="projectImageFile4" />
                                <b-card-text @click="openDocument(project.projectImageUrl4)" accept="image/*" v-if="project.projectImageUrl4" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col> 
                    </b-row> 
                </b-tab>

                <b-tab title="Informacion del Predio">
                    <b-row>    
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Nombre Finca" label-for="basicInput">
                                <b-form-input v-model="project.farmName" placeholder="Digíte"/>
                            </b-form-group>
                        </b-col>             
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Dirección" label-for="basicInput">
                                <b-form-input v-model="project.locationAddress" placeholder="Digíte"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Departamento" label-for="basicInput">                        
                                <b-form-select @change="onDepartamentoSelect" v-model="project.locationState" :options="departamentosList" value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>   
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                                <b-form-select v-model="project.locationCity" :options="municipiosList" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                        <!-- <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Tamaño en Hectareas" label-for="basicInput">
                                <cleave v-model.number="project.locationSize" class="form-control" :raw="false" :options="options.number" placeholder="Digite"/>
                            </b-form-group>
                        </b-col> -->
                    </b-row>
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-checkbox v-model="project.locationIsRented"  checked="false" name="check-button" switch inline> Predio en Arriendo </b-form-checkbox>
                        </b-col> 
                    </b-row>

                    <b-row>
                        <b-col md="8" xl="8" class="mb-1">
                            <label for="example-datepicker">Indicaciones Para Llegar</label>
                            <b-form-textarea v-model="project.locationArrivalLIndications" id="textarea-default" placeholder="Digite Indicaciones" rows="10"/>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                            
                            <!-- <label for="example-datepicker">Seleccione en el Mapa</label>
                            <l-map :zoom="zoom" :center="center" style="max-height:250px">
                            <l-tile-layer :url="url" />
                            <l-marker :lat-lng="markerLatLng">
                                <l-popup>You're here!</l-popup>
                            </l-marker>
                            </l-map> -->
                        </b-col>            
                    </b-row>
                </b-tab>

                <b-tab title="Documentos">
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Proyeccion Financiera del Proyecto" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="financialProjectionFile" />
                                <b-card-text @click="openDocument(project.financialProjectionUrl)" v-if="project.financialProjectionUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Certificado de Libertad y Tradición" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="libertadYTradicionCertificateFile" />
                                <b-card-text @click="openDocument(project.libertadYTradicionCertificateUrl)" v-if="project.libertadYTradicionCertificateUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Certificado Uso del Suelo - Vocación Ganadera" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="usoDelSueloCertificateFile" />
                                <b-card-text @click="openDocument(project.usoDelSueloCertificateUrl)" v-if="project.usoDelSueloCertificateUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Registro Sanitario de Predio" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="registroSanitarioFile" />
                                <b-card-text @click="openDocument(project.registroSanitarioUrl)" v-if="project.registroSanitarioUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Último Soporte Vacunacion" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="ultimoSoporteVacunacionFile" />
                                <b-card-text @click="openDocument(project.ultimoSoporteVacunacionUrl)" v-if="project.ultimoSoporteVacunacionUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="project.locationIsRented" md="3" xl="3" class="mb-1">
                            <b-form-group label="Contrato Arrendamiento" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="contratoDeArriendoFile" />
                                <b-card-text @click="openDocument(project.contratoDeArriendoUrl)" v-if="project.contratoDeArriendoUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>                        
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Determinantes Riesgos Ambientales y Sociales" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="determinantesRiesgosAmbientalesYSocialesFile" />
                                <b-card-text @click="openDocument(project.determinantesRiesgosAmbientalesYSocialesUrl)" v-if="project.determinantesRiesgosAmbientalesYSocialesUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>                                
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Contrato Prestación de Servicios (Joint Venture)" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="contratoPrestacionServiciosFile" />
                                <b-card-text @click="openDocument(project.contratoPrestacionServiciosUrl)" v-if="project.contratoPrestacionServiciosUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>                                
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Contrato Colaboración (Joint Venture)" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="contratoColaboracionFile" />
                                <b-card-text @click="openDocument(project.contratoColaboracionUrl)" v-if="project.contratoColaboracionUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>                                
                            </b-form-group>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="SURA - Relación Hato Ganadero" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="suraRelacionHatoGanaderoFile" />
                                <b-card-text @click="openDocument(project.suraRelacionHatoGanaderoUrl)" v-if="project.suraRelacionHatoGanaderoUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="SURA - Declaración Bovino" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="suraDeclaracionBovinoFile" />
                                <b-card-text @click="openDocument(project.suraDeclaracionBovinoUrl)" v-if="project.suraDeclaracionBovinoUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="SURA - Declaración Tipo de Productor" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="suraDeclaracionTipoDeProductorFile" />
                                <b-card-text @click="openDocument(project.suraDeclaracionTipoDeProductorUrl)" v-if="project.suraDeclaracionTipoDeProductorUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="SURA - Cotización Seguro" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="suraCotizacionSeguroFile" />
                                <b-card-text @click="openDocument(project.suraCotizacionSeguroUrl)" v-if="project.suraCotizacionSeguroUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row> 
                    <b-row>
                    </b-row>
                    <b-row>
                        <h4>Modelos para descargar</h4>
                    </b-row>
                    <b-row>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Proyección Financiera</h5>
                                <b-card-text @click="openTemplateDocument('proyeccionFinancieraFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Contrato Colaboración (Joint Venture)</h5>
                                <b-card-text @click="openTemplateDocument('contratoColaboracionFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Contrato Prestacion de Servicios (Joint Venture)</h5>
                                <b-card-text @click="openTemplateDocument('contratoPrestacionServiciosFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Determinantes Riesgos Ambientales y Sociales</h5>
                                <b-card-text @click="openTemplateDocument('determinantesRiesgosAmbientalesYSocialesFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Relación Hato Ganadero</h5>
                                <b-card-text @click="openTemplateDocument('suraRelacionHatoGanaderoFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Declaración Bovino</h5>
                                <b-card-text @click="openTemplateDocument('suraDeclaracionBovinoFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                        <b-col md="2" lg="2" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                            <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="FileIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                                <h5 class="font-weight-bolder mb-0">Declaración Tipo de Productor</h5>
                                <b-card-text @click="openTemplateDocument('suraDeclaracionTipoDeProductorFile')" class="font-small-3 mb-0 sosty-pointer">Descargar</b-card-text>
                            </b-media-body>
                            </b-media>
                        </b-col>
                    </b-row>          
                </b-tab>
            </b-tabs>  
        </b-card-code>

        <b-card-code>
            <b-row class="justify-content-end">                
                <b-col v-if="!processing" class="mt-2" lg="2">
                    <b-button variant="outline-primary" @click="goToMyProjects" block>Ir a mis proyectos</b-button>
                </b-col>
                <b-col v-if="project.projectID" class="mt-2" md="2" lg="2" >
                    <b-button variant="outline-primary" @click="viewProjectPublicDetails(project.projectCode)" block>Ver Proyecto</b-button>
                </b-col>
                <b-col v-if="!processing" class="mt-2" md="2" lg="2" >
                    <b-button variant="primary" @click="saveProjectDetails" block>Guardar</b-button>
                </b-col>
                <b-col v-if="project.projectStatus=='Nuevo'" class="mt-2" md="2" lg="2" > 
                    <b-button variant="warning" v-b-modal.modal-send-project block >Enviar Proyecto</b-button>
                </b-col>
                <!-- <b-col v-if="!processing" class="mt-2" md="2" lg="2">
                    <b-button variant="primary" block>Aprobar</b-button>
                </b-col> -->
                <b-col v-if="processing" md="2" lg="2" offset-lg="10" offset-xl="10">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                </b-col>
            </b-row>                   
        </b-card-code>

        <b-card-body>
            <b-modal ref="my-modal" id="modal-send-project" hide-footer centered title="Enviar Proyecto">
                <b-card-text> 
                    <b-row>
                        <b-col md="12" xl="12" class="mb-1">
                            <b-form-group>                    
                            <span class="align-middle">Estás seguro que ya diligenciaste toda la información y adicionaste toda la documentación requerida?</span>
                            </b-form-group>
                        </b-col>                                  
                    </b-row>            
                    <b-button class="mt-1" variant="primary" @click="SendProjectToApproval(project.projectID)" block >                
                        <span class="align-middle">Si</span>
                    </b-button> 
                    <b-button class="mt-1" variant="secondary" @click="$bvModal.hide('modal-send-project')" block>                
                        <span class="align-middle">No</span>
                    </b-button> 
                </b-card-text>
            </b-modal>
        </b-card-body>
        
    </div>
  
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code'
import {
  BModal, BTabs, BTab, BFormInput, BRow, BCol, BFormGroup, BFormDatepicker, BFormTextarea, BFormFile, BButton, BFormSelect, BSpinner,BBadge, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import departamentos from '../../assets/departamentos-municipios.json'
import { $themeConfig } from '@themeConfig'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BCardCode,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    Cleave,
    BFormDatepicker,
    BFormTextarea,
    LMap, 
    LTileLayer, 
    LMarker, 
    LPopup,
    BFormFile,
    BButton,
    BFormSelect,
    BSpinner,
    BBadge,
    BCardText,
    BFormCheckbox,
  },
  data() {
    return {
      processing: false,
      project: {},
      form: {
        cardNumber: null,
        date: null,
        phone: null,
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },  
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 10,
      center: [4.13540, -73.61719],
      markerLatLng: [4.13540, -73.61719, { draggable: 'true' }],    
      projectID: null,
      projectTypeOptions: [
        { value: 'Levante', text: 'Levante' },
        { value: 'Ceba', text: 'Ceba' },
        { value: 'Cria', text: 'Cria' },
      ],  
      projectSexOptions: [
        { value: 'Machos', text: 'Machos' },
        { value: 'Hembras', text: 'Hembras' },
      ],
      projectCattleTypeOptions: [
        { value: 'Bufalino', text: 'Bufalino' },
        { value: 'Vacuno', text: 'Vacuno' },
      ],  
      departamentosList: departamentos,
      municipiosList: [],
      financialProjectionFile: null,
      libertadYTradicionCertificateFile: null,
      usoDelSueloCertificateFile: null,
      registroSanitarioFile: null,
      ultimoSoporteVacunacionFile: null,
      contratoDeArriendoFile: null,   
      contratoColaboracionFile: null,
      contratoPrestacionServiciosFile: null,
      determinantesRiesgosAmbientalesYSocialesFile: null,
      suraRelacionHatoGanaderoFile: null,
      suraDeclaracionBovinoFile: null,
      suraDeclaracionTipoDeProductorFile: null,   
      suraCotizacionSeguroFile: null,
      projectImageFile1: null,
      projectImageFile2: null,
      projectImageFile3: null,
      projectImageFile4: null, 
      kilosEnPie: null,
      userIsAdmin: false,
      videoFile1: null,  
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      this.projectID = this.$route.query.projectID;
      this.GetProjectById();
    } else {
      this.projectID = null;      
    }    

    var user = getUserData();
    this.userIsAdmin = user.userType == "Admin";
    console.log('user', user)
    if(!this.project.mandatoPercentage) this.project.mandatoPercentage = 2            
  },
  watch:{
      financialProjectionFile: function(val) { this.financialProjectionFile = val; this.uploadFile(val, 'financialProjectionFile'); },
      libertadYTradicionCertificateFile: function(val) { this.libertadYTradicionCertificateFile = val; this.uploadFile(val, 'libertadYTradicionCertificateFile'); },
      usoDelSueloCertificateFile: function(val) { this.usoDelSueloCertificateFile = val; this.uploadFile(val, 'usoDelSueloCertificateFile'); },
      registroSanitarioFile: function(val) { this.registroSanitarioFile = val; this.uploadFile(val, 'registroSanitarioFile'); },
      ultimoSoporteVacunacionFile: function(val) { this.ultimoSoporteVacunacionFile = val; this.uploadFile(val, 'ultimoSoporteVacunacionFile'); },
      contratoDeArriendoFile: function(val) { this.contratoDeArriendoFile = val; this.uploadFile(val, 'contratoDeArriendoFile'); },
      contratoColaboracionFile: function(val) { this.contratoColaboracionFile = val; this.uploadFile(val, 'contratoColaboracionFile'); },
      contratoPrestacionServiciosFile: function(val) { this.contratoPrestacionServiciosFile = val; this.uploadFile(val, 'contratoPrestacionServiciosFile'); },
      determinantesRiesgosAmbientalesYSocialesFile: function(val) { this.determinantesRiesgosAmbientalesYSocialesFile = val; this.uploadFile(val, 'determinantesRiesgosAmbientalesYSocialesFile'); },
      suraRelacionHatoGanaderoFile: function(val) { this.suraRelacionHatoGanaderoFile = val; this.uploadFile(val, 'suraRelacionHatoGanaderoFile'); },
      suraDeclaracionBovinoFile: function(val) { this.suraDeclaracionBovinoFile = val; this.uploadFile(val, 'suraDeclaracionBovinoFile'); },
      suraDeclaracionTipoDeProductorFile: function(val) { this.suraDeclaracionTipoDeProductorFile = val; this.uploadFile(val, 'suraDeclaracionTipoDeProductorFile'); },
      suraCotizacionSeguroFile: function(val) { this.suraCotizacionSeguroFile = val; this.uploadFile(val, 'suraCotizacionSeguroFile'); },      
      projectImageFile1: function(val) { this.projectImageFile1 = val; this.uploadFile(val, 'projectImageFile1'); },
      projectImageFile2: function(val) { this.projectImageFile2 = val; this.uploadFile(val, 'projectImageFile2'); },
      projectImageFile3: function(val) { this.projectImageFile3 = val; this.uploadFile(val, 'projectImageFile3'); },
      projectImageFile4: function(val) { this.projectImageFile4 = val; this.uploadFile(val, 'projectImageFile4'); },
      kilosEnPie: function(val) { this.kilosEnPie = val; },
      videoFile1: function(val) {this.videoFile1 = val; this.uploadVideo(val, 'videoFile1')},
  },
   methods: {
    GetProjectById() {
        this.processing = true;
        axios.get('/Project/GetProjectById', { params: { projectID: this.projectID }})
        .then(response => {
           this.processing = false;
           this.project = response.data;
           this.kilosEnPie = this.project.investmentRequired
           if(this.project.locationCity){
               this.municipiosList.push(this.project.locationCity);
           }
           if(!this.project.sostyComission) this.project.sostyComission = 3;
           if(!this.project.mandatoPercentage) this.project.mandatoPercentage = 2
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    saveProjectDetails() {
        this.processing = true;
        var userData = getUserData()
        var payload = {
            user: userData,
            project: this.project
        }
        var isNew = this.project.projectID == null || this.project.projectID == undefined;        
        axios.post('/Project/SaveProject', payload)
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });  
            this.project = response.data;
            if(isNew) {
                this.$router.push('create-edit-project?projectID=' + this.project.projectID);
            }            
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },    
    uploadFile(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();
        formData.append('file', fileToUpload);
        axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
            this.saveProjectDetails(); 
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    setFileUrl(fileType, fileUrl) {
        switch(fileType) {
            case 'financialProjectionFile':
                this.project.financialProjectionUrl = fileUrl;
                break;
            case 'libertadYTradicionCertificateFile':
                this.project.libertadYTradicionCertificateUrl = fileUrl;
                break;
            case 'usoDelSueloCertificateFile':
                this.project.usoDelSueloCertificateUrl = fileUrl;
                break;
            case 'registroSanitarioFile':
                this.project.registroSanitarioUrl = fileUrl;
                break;
            case 'ultimoSoporteVacunacionFile':
                this.project.ultimoSoporteVacunacionUrl = fileUrl;
                break;
            case 'contratoDeArriendoFile':
                this.project.contratoDeArriendoUrl = fileUrl;
                break;
            case 'contratoColaboracionFile':
                this.project.contratoColaboracionUrl = fileUrl;
                break;
            case 'contratoPrestacionServiciosFile':
                this.project.contratoPrestacionServiciosUrl = fileUrl;
                break;
            case 'determinantesRiesgosAmbientalesYSocialesFile':
                this.project.determinantesRiesgosAmbientalesYSocialesUrl = fileUrl;
                break;
            case 'suraRelacionHatoGanaderoFile':
                this.project.suraRelacionHatoGanaderoUrl = fileUrl;
                break;
            case 'suraDeclaracionBovinoFile':
                this.project.suraDeclaracionBovinoUrl = fileUrl;
                break;
            case 'suraDeclaracionTipoDeProductorFile':
                this.project.suraDeclaracionTipoDeProductorUrl = fileUrl;
                break;
            case 'suraCotizacionSeguroFile':
                this.project.suraCotizacionSeguroUrl = fileUrl;
                break;
            case 'projectImageFile1':
                this.project.projectImageUrl1 = fileUrl;
                break;
            case 'projectImageFile2':
                this.project.projectImageUrl2 = fileUrl;
                break;
            case 'projectImageFile3':
                this.project.projectImageUrl3 = fileUrl;
                break;
            case 'projectImageFile4':
                this.project.projectImageUrl4 = fileUrl;
                break;
            case 'videoFile1':
                this.project.projectVideoUrl = fileUrl;
                break;
        }
    },
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },
    openTemplateDocument(fileType) {
        var fileUrl = '';
        switch(fileType) {            
            case 'contratoColaboracionFile':
                fileUrl = 'https://drive.google.com/file/d/1e4DLKODOnDrwFd_v6gBsYYGjD88MACfh/view?usp=sharing';
                break;
            case 'contratoPrestacionServiciosFile':
                fileUrl = 'https://drive.google.com/file/d/1iP7KDxT-fSkdwnSYGUsNWeVrbyquCrTm/view?usp=sharing';
                break;
            case 'suraRelacionHatoGanaderoFile':
                fileUrl = 'https://drive.google.com/file/d/1q0Ygnxu9NTUHD_cKOdyR7c8Bphast9S3/view?usp=sharing';
                break;
            case 'suraDeclaracionBovinoFile':
                fileUrl = 'https://drive.google.com/file/d/1WKhNYgp8Toqj9fHwtTW2zzxmd4UlEI5v/view?usp=sharing';
                break;
            case 'suraDeclaracionTipoDeProductorFile':
                fileUrl = 'https://drive.google.com/file/d/1wke4Y1R2xeHuXg-uuscsXcR-PA-6FNWT/view?usp=sharing';
                break;
            case 'proyeccionFinancieraFile':
                fileUrl = 'https://drive.google.com/file/d/1qbziCO43MOUX_UL-Hvubn1PZhzN5xRX2/view?usp=sharing';
                break;
        }
        window.open(fileUrl, '_blank');
    },
    goToMyProjects(){
        this.$router.push('my-projects')
    },
    onDepartamentoSelect(event){
        this.locationState = event;        
        var departamento = this.departamentosList.find(x => x.departamento === event);
        this.municipiosList = departamento.ciudades;
    },

    amountOfCattlesTyped(event){
        this.project.amountOfCattles = parseInt(event);        
        this.calculateInvestmentRequired()
    },

    projectTypeSelected(event){
        this.project.projectType = event        
    },

    initialWeightTyped(event){        
        this.kiloBase = parseFloat(event);
        this.project.initialWeight = parseFloat(event);        
        this.calculateInvestmentRequired()
    },

    finalWeightTyped(event){
        this.project.finalWeight = parseFloat(event)    
    },

    calculateInvestmentRequired(){
        if(this.project.amountOfCattles && this.kiloBase){
            var a = parseFloat(this.project.amountOfCattles) * parseFloat(this.kiloBase)
            this.project.investmentRequired  = a
            this.kilosEnPie = a;
        }else{
            this.project.investmentRequired = 0
            this.kilosEnPie = 0;
        }        
    },

    kilogramSubValueChanged(){    
        this.calculateTotalPricePerKilogram()
    },

    calculateTotalPricePerKilogram(){   
        this.project.totalPricePerKilogram = parseFloat(parseFloat(this.project.initialKilogramPrice 
                                            + this.project.estimatedFreightCost 
                                            + this.project.insurancePricePerKilogram 
                                            + this.project.orejerasPerKilogram 
                                            + this.project.fourPerThousandPerKilogram 
                                            + this.project.sostyComission
                                            + this.project.mandatoPercentage).toFixed(2));
    },
    calculateTotalCostBreedingProject(){   
        this.project.totalCostBreedingProject = parseFloat(parseFloat(this.project.totalCostCows 
                                            + this.project.totalCostBulls 
                                            + this.project.totalFreightCost 
                                            + this.project.totalInsurance 
                                            + this.project.totalOrejeras 
                                            + this.project.totalFourPerThousand
                                            + this.project.totalSostyComercialization).toFixed(2));
        this.calculateUnitsPrice();
    },

    calculateUnitsPrice(){   
        this.project.unitPrice = parseFloat(parseFloat(parseFloat(this.project.totalCostBreedingProject)/parseFloat(this.project.totalUnits)).toFixed(2));
    },

    viewProjectPublicDetails(projectCode) {            
        let route = this.$router.resolve({path: 'public-project-details?projectCode=' + projectCode});
        window.open(route.href, '_blank');
    },

    SendProjectToApproval(projectID){
        axios.get('/Project/SendProjectToApproval', { params: { projectID }})
        .then(response => {
            this.GetProjectById()  
            this.$bvToast.toast("Tu Proyecto ha sido enviado para aprobación", { title: 'Ok', variant: 'success', solid: true, });
            this.$refs['my-modal'].hide()
            }            
        )       
    },
    uploadVideo(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();            
        formData.append('file', fileToUpload);
        axios.post('/File/UploadVideo', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
        this.processing = false;
        if(response.data) {                
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Video subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });            
            
        } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
        }
        })
        .catch(error => {
        this.processing = false;
            if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    openVideo(url){            
        this.url = url
        window.open(this.url, '_blank');
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>